<template>

<div class="donate">

	<div class="donate-logo" />

	<router-link :to="$link('get-involved/donate')" class="donate-button">Donate</router-link>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.donate {
	background-color: #f5f5f5;
	border-radius: 21px;
	padding: 68px 10px 20px 10px;
	text-align: center;
	margin-top: 48px;
	margin-bottom: 40px;
}

.donate-logo {
	width: 160px;
	height: 96px;
	position: absolute;
	top: -48px;
	left: 50%;
	margin-left: -80px;
	background-position: 50% 50%;
	background-color: #fff;
	border-radius: 23px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

.is-aa .donate-logo {
	background-image: url(~@/assets/logo.aa.png);
}

.is-afa .donate-logo {
	background-image: url(~@/assets/logo.afa.png);
}

.is-stars .donate-logo {
	background-image: url(~@/assets/logo.stars.png);
}

.donate-button {
	height: 36px;
	display: block;
	background-color: #C21920;
	text-transform: uppercase;
	color: #fff;
	padding: 0px 20px;
	line-height: 36px;
	font-size: 16px;
	font-weight: bold;
	border-radius: 19px;
}

</style>
